import { Component, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from './api.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { commonErrorMsg, ErrorMsgSettings } from './model/error-messages-settings-model';
import { SnackBarDangerComponent } from './common-components/components/snack-bar-danger/snack-bar-danger.component';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
     selector: 'app-root',
     templateUrl: './app.component.html',
     styleUrls: ['./app.component.scss']
})

export class AppComponent {
     title = 'GVA-UI-NEW';
     currentYear = (new Date()).getFullYear();
     url:string = '';
     version: string = '';
     private readonly destroySubject = new Subject();
     constructor(private router: Router, public progress: ApiService, private snackBar: MatSnackBar,) {}

     @HostListener('window:beforeunload', [ '$event' ])
     beforeUnloadHandler(event) {
          this.progress.multiFileUploadNames$
          .pipe(takeUntil(this.destroySubject))
          .subscribe((res: any) => {
               if(res?.length) {
                    const payLoad: string = res.toString();
                    this.progress.uploadCleanUp(payLoad)
                    .pipe(takeUntil(this.destroySubject))
                    .subscribe((res: any) => {
                         
                    },
                    (err) => {
                         
                    });
                    for (let i=0; i< res.length; i++) {
                         this.progress.removeMultiFileNames(res);
                    }
               }
          },
          (err) => {
               
          });
          // this.progress.multiFileUpload$
          // .pipe(takeUntil(this.destroySubject))
          // .subscribe((res: any) => {
          //      if(res?.length) {
          //           let ids: Array<number> = []; 
          //           for (let i=0; i< res.length; i++) {
          //                ids.push(res[i].user_file_name)
          //           }
          //           if(ids.length) {
          //                const payLoad: string = ids.toString();
          //                this.progress.uploadCleanUp(payLoad)
          //                .pipe(takeUntil(this.destroySubject))
          //                .subscribe((res: any) => {
                              
          //                },
          //                (err) => {
                              
          //                });
          //           }
          //      }
          // },
          // (err) => {
               
          // });
     }


     ngOnInit(): void {
          this.router.events.subscribe((event) => {
               this.url = this.router.url;
          });
          this.progress.version$.subscribe((value) => {
               this.version = value;
           })
     }
}
